import { atom } from "jotai";

import { IUser } from "@app-interface/user";

import { IToken } from "../../interface/auth";
import { INIT_PAYLOAD } from "../constants";
import { Payload } from "../types";

const isLoggedAtom = atom(false);

const tokenAtom = atom("");

const signinPayloadAtom = atom<Payload<IToken>>(INIT_PAYLOAD);
const profilePayloadAtom = atom<Payload<IToken>>(INIT_PAYLOAD);

const permissionKeyAtom = atom<string>("");

const profileAtom = atom({} as IUser);

export {
  isLoggedAtom,
  tokenAtom,
  signinPayloadAtom,
  permissionKeyAtom,
  profileAtom,
  profilePayloadAtom,
};
