import { AxiosError } from "axios";

export const getErrorPathKey = (error: {
  message: string;
  statusCode: number;
}): string => {
  const { message } = error;

  const paths = message.toUpperCase().replaceAll(" ", ".");

  return `apiError.${paths}`;
};

export const getErrorMessage = (error: {
  message: string;
  statusCode: number;
}): string => {
  const { statusCode } = error;
  return `errorCode.${statusCode}`;
};

export const throwErrorMessage = (error: AxiosError) => {
  if (error?.response?.data) {
    return getErrorMessage(
      error?.response.data as { message: string; statusCode: number },
    );
  }

  return error?.message;
};
