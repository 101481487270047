import {
  DownOutlined,
  LogoutOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
} from "@ant-design/icons";
import { Button, Dropdown, Layout, MenuProps, Space } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useOutlet } from "react-router-dom";

import Sidebar from "../../components/layout/Sidebar";
import { useAuthWriteOnly } from "../../jotai/auth";

const { Header, Content } = Layout;

const items: MenuProps["items"] = [
  {
    label: <span>English</span>,
    key: "en",
  },
  {
    label: <span>Tiếng Việt</span>,
    key: "vi",
  },
];

export default function HomePage() {
  const { t, i18n } = useTranslation();
  const [collapsed, setCollapsed] = useState(false);
  const outlet = useOutlet();
  const { onSignout } = useAuthWriteOnly();

  return (
    <Layout className="min-h-screen">
      <Sidebar
        isCollapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}
      />
      <Layout>
        <Header className="flex bg-white p-0 items-center">
          <Button
            type="text"
            icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            onClick={() => setCollapsed(!collapsed)}
            style={{
              fontSize: "16px",
              width: 64,
              height: 64,
            }}
          />
          <div className="flex-1"></div>
          <div className="px-4 cursor-pointer">
            <Dropdown
              menu={{
                items,
                onClick: (e) => i18n.changeLanguage(e.key),
                selectedKeys: [i18n.language],
              }}
              trigger={["click"]}
              placement="bottomRight"
            >
              <Space className="capitalize">
                {i18n.language}
                <DownOutlined />
              </Space>
            </Dropdown>
          </div>
          <Button className="mr-4" onClick={onSignout}>
            <LogoutOutlined /> {t("button.logout")}
          </Button>
        </Header>
        <Content
          style={{
            margin: "24px 16px",
            padding: 24,
            minHeight: 280,
          }}
        >
          {outlet}
        </Content>
      </Layout>
    </Layout>
  );
}
