import jwtDecode from "jwt-decode";

import { IToken, SigninRequest } from "@app-interface/auth";
import { throwErrorMessage } from "@app-utils/errorHandler";

import { baseApi } from "./common";

const signin = async (data: SigninRequest): Promise<IToken> => {
  try {
    const response = await baseApi.post(`auth/signin`, data);
    const decoded = jwtDecode(response.data.accessToken) as any;

    if (!["admin", "finance", "counter"].includes(decoded.role))
      throw Error("You dont have permission to access");

    return response.data;
  } catch (error: any) {
    throw throwErrorMessage(error);
  }
};

const authServices = {
  signin,
};

export default authServices;
