import { Modal } from "antd";
import React from "react";

export default function ModalRingExist({
  isOpen,
  onCancel,
  onPrint,
}: {
  isOpen: boolean;
  onCancel: () => void;
  onPrint: () => void;
}) {
  return (
    <Modal
      title={"Add ring failed"}
      open={isOpen}
      onCancel={onCancel}
      onOk={onPrint}
      okText="Print"
    >
      <div>
        This ring is already registered in system. Do you want to print QRCode?
      </div>
    </Modal>
  );
}
