import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import {
  AutoComplete,
  Button,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  message,
} from "antd";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { IItem } from "@app-interface/item";
import itemServices from "@app-services/itemServices";
import useService from "@app-services/shared/use_service";

const useBulkItem = () => useService(itemServices.bulk);

export const options = Array(11)
  .fill(0)
  .map((_, index: number) => ({
    label: `Ring size ${index + 5}`,
    value: `${5 + index}`,
  }));

export default function GenerateRingsModal({
  isOpen,
  onSuccess,
  onCancel,
}: {
  isOpen?: boolean;
  itemData?: IItem | null;
  onSuccess?: () => void;
  onCancel?: () => void;
}) {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [payload, bulk, clearPayload] = useBulkItem();

  useEffect(() => {
    return () => {
      clearPayload();
      form?.resetFields();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    if (payload.success) {
      onSuccess?.();
      onCancel?.();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payload.success]);

  useEffect(() => {
    if (payload.error) {
      message.error(t(payload.error));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payload.error]);

  const handleFinish = async (values: any) => {
    const newVal = values.items.map((item: any) => ({
      ...item,
      size: Number(item.size),
    }));
    await bulk({ items: newVal });
  };

  return (
    <Modal
      title={"Generate Ring"}
      open={isOpen}
      onCancel={onCancel}
      footer={null}
      className="w-[90%] md:w-[60%]"
    >
      <Form
        disabled={payload.loading}
        layout="vertical"
        form={form}
        onFinish={handleFinish}
        initialValues={{ items: [{ quantity: null, size: null }] }}
      >
        <Form.List name="items">
          {(fields, { add, remove }) => {
            return (
              <div>
                {fields.map((field, index) => (
                  <div key={index}>
                    <div className="flex md:flex-row flex-col gap-y-2 gap-x-4">
                      <Form.Item
                        className="flex-1"
                        label="Quantity"
                        name={[field.name, "quantity"]}
                        rules={[
                          {
                            required: true,
                            message: t("form.quantity.requireRule"),
                          },

                          {
                            type: "number",
                            min: 1,
                            message: "Quantity cannot be less than 1",
                          },
                        ]}
                      >
                        <InputNumber
                          className="w-full"
                          placeholder="input placeholder"
                          type="number"
                        />
                      </Form.Item>
                      <Form.Item
                        className="flex-1"
                        label="Ring Size"
                        name={[field.name, "size"]}
                        rules={[
                          {
                            required: true,
                            message: t("form.ringSize.requireRule"),
                          },
                          {
                            message: "Quantity must be between 5 and 15",
                            type: "number",
                            min: 5,
                            max: 15,
                            transform: (value) => {
                              return Number(value);
                            },
                          },
                        ]}
                      >
                        <AutoComplete allowClear options={options}>
                          <Input />
                        </AutoComplete>
                      </Form.Item>

                      <div style={{ width: 20, marginTop: 36 }}>
                        {fields.length > 1 ? (
                          <MinusCircleOutlined
                            style={{ color: "red" }}
                            color="red"
                            onClick={() => remove(field.name)}
                          />
                        ) : null}
                      </div>
                    </div>
                  </div>
                ))}
                <Form.Item className="flex justify-end">
                  <Button
                    type="dashed"
                    onClick={() => add({ size: null, quantity: null })}
                  >
                    <PlusOutlined /> Add
                  </Button>
                </Form.Item>
              </div>
            );
          }}
        </Form.List>
        <Divider />
        <Form.Item className="flex justify-end">
          <Button onClick={onCancel} className="mr-4" loading={payload.loading}>
            Cancel
          </Button>
          <Button type="primary" htmlType="submit" loading={payload.loading}>
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}
