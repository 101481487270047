import { Button, Form, Modal, Select, message } from "antd";
import { IOrder, OrderStatus } from "../../../interface/order";
import { useEffect, useState } from "react";
import orderServices from "../../../services/orderServices";

export default function AddOrEditOrderModal({
  isOpen,
  orderData,
  onSuccess,
  onCancel,
}: {
  isOpen?: boolean;
  orderData?: IOrder | null;
  onSuccess?: () => void;
  onCancel?: () => void;
}) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (orderData) {
      // setData(orderData);
      form.setFieldsValue(orderData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderData]);

  useEffect(() => {
    return () => {
      form.resetFields();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const handleFinish = async (values: any) => {
    setLoading(true);
    let response = null;

    if (values.price) values.price = parseInt(values.price);
    try {
      if (orderData) {
        response = await orderServices.updateOrder(orderData.id, values);
      } else {
        response = await orderServices.createOrder(values);
      }
      if (!!response) {
        message.success(
          orderData ? "Update order success" : "Create order success"
        );
        onSuccess?.();
      }
    } catch (error: any) {
      message.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      title={orderData ? "Edit Order" : "Create Order"}
      open={isOpen}
      onCancel={onCancel}
      footer={null}
    >
      <Form
        layout="vertical"
        initialValues={{ status: orderData?.status }}
        form={form}
        onFinish={handleFinish}
      >
        <Form.Item label="Order Status" name="status">
          <Select>
            {Object.keys(OrderStatus).map((i) => (
              <Select.Option key={i} value={i}>
                {i}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item className="flex justify-end">
          <Button onClick={onCancel} className="mr-4">
            Cancel
          </Button>
          <Button type="primary" htmlType="submit" loading={loading}>
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}
