import {
  DeleteOutlined,
  EditOutlined,
  PrinterOutlined,
  PropertySafetyOutlined,
} from "@ant-design/icons";
import { Button, InputRef, Popconfirm, Space, Table, message } from "antd";
import { format } from "date-fns";
import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import useService from "@app-services/shared/use_service";

import { IResponseData } from "../../interface/common";
import { IItem } from "../../interface/item";
import itemServices from "../../services/itemServices";
import AddOrEditRingModal from "./components/AddOrEditRingModal";
import { getColumnSearchProps } from "./components/FilteredTable";
import GenerateRingsModal from "./components/GenerateRingsModal";
import ItemExportButton from "./components/ItemExportButton";
import ModalActivation from "./components/ModalActivation";
import ModalRingExist from "./components/ModalRingExist";

const { ipcRenderer } = window.electron || {};
const useSearch = () => useService(itemServices.search);
// const statusOptions = [
//   {
//     label: "NOT_DONE",
//     value: "NOT_DONE",
//   },
//   {
//     label: "DONE",
//     value: "DONE",
//   },
// ];

export default function RingsPage() {
  const [activateModal, setActivateModal] = useState("");
  const [messageApi, contextHolder] = message.useMessage();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [itemData, setItemData] = useState<IItem | null>(null);
  const [openModal, setOpenModal] = useState(false);
  const [openGenerateModal, setOpenGenerateModal] = useState(false);
  const [openModalExist, setOpenModalExist] = useState(false);
  const [searchParams, setSearchParams] = useState<null | {
    [key: string]: string;
  }>();
  const searchInput = useRef<InputRef>(null);
  const [data, setData] = useState<IResponseData<IItem>>({
    metadata: {
      limit: 20,
      offset: 0,
      count: 0,
    },
  });

  const isFiltered = useMemo(
    () => searchParams && Object.keys(searchParams).length,
    [searchParams],
  );

  const [searchPayload, onSearch, clearSearchPayload] = useSearch();

  const cardRef = useRef<any>(null);

  useEffect(() => {
    handleGetItems();
    registerNfcUsb();

    return () => {
      if (ipcRenderer) {
        ipcRenderer.removeAllListeners();
      }
      clearSearchPayload();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (searchParams) {
      if (isFiltered) {
        onSearch(searchParams);
      } else if (!loading && !searchPayload.loading) {
        handleGetItems();
      }
    }
  }, [searchParams]);

  const registerNfcUsb = () => {
    if (!ipcRenderer) return;
    ipcRenderer.on("fromMain", function (_: any, event: any) {
      console.log(event); // Returns: {'SAVED': 'File Saved'}
      if (event.card) {
        message.success(
          `Received nfc ring with uid: ${event?.card?.uid.toUpperCase()}`,
        );
        cardRef.current = event.card;
        handleAddRingFromNfcUsb(event.card.uid.toUpperCase());
      } else if (event.error) {
        message.error(event.error?.message);
      } else if (event.text) {
        message.info(event.text);
      }
    });
  };

  const handleGetItems = async (metadata?: any) => {
    setLoading(true);
    try {
      const response = await itemServices.getItems(metadata || data.metadata);
      setData(response);
    } catch (error) {
      message.error(t(error as string));
    } finally {
      setLoading(false);
    }
  };

  const handleTableChange = (tableData: any) => {
    const newMetadata = {
      limit: data.metadata?.limit,
      offset: (tableData.current - 1) * +data?.metadata?.limit,
    };
    setData((prev) => ({
      ...prev,
      metadata: {
        ...prev.metadata,
        ...newMetadata,
      },
    }));
    handleGetItems(newMetadata);
  };

  const handleModalCancel = () => {
    setItemData(null);
    setOpenModal(false);
  };

  const handleSuccess = () => {
    handleGetItems();
    handleModalCancel();
  };

  const handleDelete = async (id: number) => {
    setLoading(true);
    try {
      const response = await itemServices.deleteItem(id);

      if (!!response) {
        messageApi.success("Deleted item success");
        handleGetItems();
      } else {
        // eslint-disable-next-line no-throw-literal
        throw "Error when delete item";
      }
    } catch (error: any) {
      messageApi.error(t("errorCode.500"));
    } finally {
      setLoading(false);
    }
  };

  const handleAddRingFromNfcUsb = async (uid: string) => {
    try {
      await itemServices.createItem({ uid, label: "", status: "DONE" });
      message.success(`Add ring ${uid} to system successfully`);
      handleSendEventToPrinter(uid);
      handleGetItems();
    } catch (error) {
      if (error === "errorCode.400") {
        setOpenModalExist(true);
      }
    }
  };

  const handlePrint = () => {
    setOpenModalExist(false);
    if (cardRef?.current?.uid) {
      handleSendEventToPrinter(cardRef?.current?.uid);
    }
  };

  const handleSendEventToPrinter = (uid: string) => {
    if (!ipcRenderer) {
      message.error("Function does not support.");
      return;
    }
    ipcRenderer.send(
      "printer-event",
      JSON.stringify({ card: { uid: uid.toUpperCase() } }),
    );
  };

  const handleSearch = (
    selectedKeys: string[],
    close: any,
    dataIndex: any,
    confirm: any,
  ) => {
    confirm();
    if (selectedKeys[0]) {
      setSearchParams((prev) => ({
        ...prev,
        [dataIndex]: selectedKeys.length > 1 ? selectedKeys : selectedKeys[0],
      }));
    }
    close();
  };

  const handleReset = (
    clearFilters: () => void,
    dataIndex: string,
    confirm: any,
    close: any,
  ) => {
    setSearchParams((prev) => {
      const data = Object.assign({}, prev);
      if (data[dataIndex]) {
        delete data[dataIndex];
      }
      return data;
    });
    clearFilters();
    confirm();
    close();
  };

  return (
    <div>
      {contextHolder}
      <div className="flex justify-end mb-4">
        <Space>
          <ItemExportButton />
          <Button type="primary" onClick={() => setOpenGenerateModal(true)}>
            {t("button.genRingList")}
          </Button>
          <Button type="primary" onClick={() => setOpenModal(true)}>
            {t("button.createRing")}
          </Button>
          <Button
            type="dashed"
            onClick={() => {
              setSearchParams({});
            }}
          >
            {t("button.clearFilter")}
          </Button>
        </Space>
      </div>
      <Table<IItem>
        rowKey={(row) => row.id?.toString() ?? ""}
        loading={loading}
        columns={[
          {
            title: "Label",
            dataIndex: "label",
            key: "label",
          },
          {
            title: "UID",
            dataIndex: "uid",
            key: "uid",
          },
          {
            title: "User Id",
            dataIndex: "userId",
            key: "userId",
          },
          {
            title: "Manufacturing ID",
            dataIndex: "eid",
            key: "eid",
            ...getColumnSearchProps(
              "eid",
              searchInput,
              handleSearch,
              handleReset,
            ),
          },

          {
            title: "Manufacturing status",
            dataIndex: "status",
            key: "status",
            // ...getColumnSearchProps(
            //   "status",
            //   searchInput,
            //   handleSearch,
            //   handleReset,
            //   statusOptions,
            // ),
          },
          {
            title: "Serial No",
            dataIndex: "serialNo",
            key: "serialNo",
            ...getColumnSearchProps(
              "serialNo",
              searchInput,
              handleSearch,
              handleReset,
            ),
          },
          {
            title: "Size",
            dataIndex: "size",
            key: "size",
            // ...getColumnSearchProps(
            //   "size",
            //   searchInput,
            //   handleSearch,
            //   handleReset,
            //   options,
            // ),
          },
          {
            title: "Created At",
            dataIndex: "createdAt",
            key: "createdAt",
            render: (data) => <div>{format(new Date(data), "PPp")}</div>,
          },
          {
            title: "Updated At",
            dataIndex: "updatedAt",
            key: "updatedAt",
            render: (data) => <div>{format(new Date(data), "PPp")}</div>,
          },
          {
            title: "Action",
            dataIndex: "id",
            key: "action",
            render: (id, record) => (
              <Space>
                <div className="mr-4">
                  <PrinterOutlined
                    onClick={() => handleSendEventToPrinter(record.uid)}
                  />
                </div>

                <div className="mr-4">
                  <PropertySafetyOutlined
                    onClick={() => setActivateModal(record.uid)}
                  />
                </div>

                <EditOutlined
                  className="cursor-pointer"
                  onClick={() => {
                    setItemData(record as any);
                    setOpenModal(true);
                  }}
                />

                <Popconfirm
                  title="Delete ring"
                  description="Are you sure to delete this ring?"
                  onConfirm={() => handleDelete(id)}
                  okText="Yes"
                  cancelText="No"
                >
                  <DeleteOutlined className="cursor-pointer ml-4" />
                </Popconfirm>
              </Space>
            ),
          },
        ]}
        dataSource={
          isFiltered ? searchPayload.data?.models : (data?.models as any)
        }
        onChange={!isFiltered ? handleTableChange : undefined}
        scroll={{ x: 800 }}
        pagination={
          !isFiltered
            ? {
                total: +data?.metadata?.count,
                pageSize: +data?.metadata?.limit,
                current: +data?.metadata?.offset / +data?.metadata?.limit + 1,
              }
            : undefined
        }
      />

      <AddOrEditRingModal
        isOpen={openModal}
        itemData={itemData}
        onSuccess={handleSuccess}
        onCancel={handleModalCancel}
      />

      <ModalRingExist
        isOpen={openModalExist}
        onPrint={handlePrint}
        onCancel={() => setOpenModalExist(false)}
      />

      {activateModal && (
        <ModalActivation
          uid={activateModal}
          onCancel={() => setActivateModal("")}
        />
      )}
      {openGenerateModal && (
        <GenerateRingsModal
          isOpen={openGenerateModal}
          onCancel={() => setOpenGenerateModal(false)}
          onSuccess={handleGetItems}
        />
      )}
    </div>
  );
}
