/* ts-ignore */
import { Getter, Setter, WritableAtom } from "jotai";

export const atomService = async (
  atom: WritableAtom<unknown, [(prev: any) => any], unknown>,
  fetch: { (): Promise<any>; (): any },
  get: Getter,
  set: Setter,
) => {
  try {
    set(atom, (prev: any) => ({
      ...prev,
      success: false,
      loading: true,
      error: null,
    }));
    const data = await fetch();
    set(atom, (prev: any) => ({
      ...prev,
      success: true,
      loading: false,
      data,
    }));
    return data;
  } catch (error) {
    set(atom, (prev: any) => ({
      ...prev,
      success: false,
      loading: false,
      error,
    }));
  }
};
