import { throwErrorMessage } from "@app-utils/errorHandler";

import { CreateUserReq, IUser, UpdateUserReq } from "../interface/user";
import { baseApi } from "./common";

const getProfile = async (): Promise<IUser> => {
  try {
    const response = await baseApi.get(`user`);

    return response.data;
  } catch (error: any) {
    throw throwErrorMessage(error);
  }
};

const getUsers = async (params?: any) => {
  try {
    const response = await baseApi.get(`admin/users`, {
      params,
    });
    return response.data;
  } catch (error: any) {
    throw throwErrorMessage(error);
  }
};

const createUser = async (data: CreateUserReq) => {
  try {
    const response = await baseApi.post(`admin/users`, data);
    return response.data;
  } catch (error: any) {
    throw throwErrorMessage(error);
  }
};

const updateUser = async (id: number, data: UpdateUserReq) => {
  try {
    const response = await baseApi.patch(`admin/users/${id}`, data);
    return response.data;
  } catch (error: any) {
    throw throwErrorMessage(error);
  }
};

const deleteUser = async (id: number) => {
  try {
    const response = await baseApi.delete(`admin/users/${id}`);
    return response.data;
  } catch (error: any) {
    throw throwErrorMessage(error);
  }
};

const userServices = {
  getUsers,
  createUser,
  updateUser,
  deleteUser,
  getProfile,
};

export default userServices;
