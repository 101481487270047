import { Button, Form, Input, Modal, Select, message } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { IItem } from "../../../interface/item";
import itemServices from "../../../services/itemServices";

export default function AddOrEditRingModal({
  isOpen,
  itemData,
  onSuccess,
  onCancel,
}: {
  isOpen?: boolean;
  itemData?: IItem | null;
  onSuccess?: () => void;
  onCancel?: () => void;
}) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (itemData) {
      // setData(itemData);
      form.setFieldsValue(itemData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemData]);

  useEffect(() => {
    return () => {
      form.resetFields();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const handleFinish = async (values: any) => {
    setLoading(true);
    let response = null;
    try {
      if (itemData?.id) {
        response = await itemServices.updateItem(itemData.id as number, values);
      } else {
        response = await itemServices.createItem(values);
      }
      onSuccess?.();
    } catch (error: any) {
      message.error(t(error));
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      title={itemData ? "Edit Ring" : "Create Ring"}
      open={isOpen}
      onCancel={onCancel}
      footer={null}
    >
      <Form layout="vertical" form={form} onFinish={handleFinish}>
        {!!itemData && (
          <Form.Item label="Manufacturing ID" name="eid">
            <Input placeholder="input placeholder" disabled={!!itemData} />
          </Form.Item>
        )}
        <Form.Item label="UID" name="uid">
          <Input placeholder="input placeholder" />
        </Form.Item>
        <Form.Item label="Label" name="label">
          <Input placeholder="input placeholder" />
        </Form.Item>
        {itemData && (
          <Form.Item className="flex-1" label="Status" name="status">
            <Select>
              <Select.Option value="NOT_DONE">NOT_DONE</Select.Option>
              <Select.Option value="DONE">DONE</Select.Option>
            </Select>
          </Form.Item>
        )}
        <Form.Item className="flex justify-end">
          <Button onClick={onCancel} className="mr-4">
            Cancel
          </Button>
          <Button type="primary" htmlType="submit" loading={loading}>
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}
