import { SearchOutlined } from "@ant-design/icons";
import type { TableColumnType } from "antd";
import { Button, Input, Select } from "antd";

export const getColumnSearchProps = (
  dataIndex: any,
  searchInputRef: any,
  onSearch: (...item: any) => any,
  onClear: (...item: any) => any,
  options?: { label: string; value: string | number }[],
): TableColumnType<any> => ({
  filterDropdown: ({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
    close,
  }) => (
    <div
      className="flex flex-col gap-4 p-4"
      onKeyDown={(e) => e.stopPropagation()}
    >
      {options ? (
        <Select
          mode="multiple"
          value={selectedKeys}
          onChange={(e) => setSelectedKeys(e)}
          options={options}
          placement="topRight"
          listHeight={150}
          className="flex-1"
          maxTagCount={2}
        />
      ) : (
        <Input
          ref={searchInputRef}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            onSearch(selectedKeys as string[], close, dataIndex, confirm)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
      )}

      <div className="flex justify-end gap-4">
        <Button
          onClick={() =>
            clearFilters && onClear(clearFilters, dataIndex, confirm, close)
          }
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
        <Button
          type="primary"
          onClick={() =>
            onSearch(selectedKeys as string[], close, dataIndex, confirm)
          }
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90 }}
        >
          Search
        </Button>
      </div>
    </div>
  ),
  filterIcon: (filtered: boolean) => (
    <div className="rounded-full p-1">
      <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />
    </div>
  ),
  onFilterDropdownOpenChange: (visible) => {
    if (visible) {
      setTimeout(() => searchInputRef.current?.select(), 100);
    }
  },
});
