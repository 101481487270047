import { atom } from "jotai";

import userServices from "@app-services/userServices";

import { LocalStorageKeys } from "../../constants/common";
import { SigninRequest } from "../../interface/auth";
import authServices from "../../services/authServices";
import { updateHeaderToken } from "../../services/common";
import { atomService } from "../utils";
import {
  isLoggedAtom,
  profileAtom,
  profilePayloadAtom,
  signinPayloadAtom,
  tokenAtom,
} from "./atoms";

const signinServiceAtom = atom(null, async (get, set, data: SigninRequest) => {
  const response = await atomService(
    signinPayloadAtom,
    () => authServices.signin(data),
    get,
    set
  );
  if (response?.accessToken) {
    updateHeaderToken(response.accessToken);
    set(isLoggedAtom, true);
    set(tokenAtom, response.accessToken);
    window.localStorage.setItem(
      LocalStorageKeys.CMS_TOKEN,
      response?.accessToken
    );
  }
});

const getProfileServiceAtom = atom(null, async (get, set) => {
  const response = await atomService(
    profilePayloadAtom,
    () => userServices.getProfile(),
    get,
    set,
  );
  if (response) {
    set(profileAtom, response);
  }
});

export { signinServiceAtom, getProfileServiceAtom };
