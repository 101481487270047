import { ExportOutlined } from "@ant-design/icons";
import {
  AutoComplete,
  Button,
  DatePicker,
  Form,
  Input,
  Modal,
  Select,
  message,
} from "antd";
import dayjs from "dayjs";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import itemServices from "@app-services/itemServices";
import useService from "@app-services/shared/use_service";

const options = Array(11)
  .fill(0)
  .map((_, index: number) => ({
    label: `Ring size ${index + 5}`,
    value: `${5 + index}`,
  }));

const useItemExport = () => useService(itemServices.export);

export default function ItemExportButton() {
  const { t } = useTranslation();
  const [exportItemResponse, exportItem, clearRequest] = useItemExport();
  const [openModal, setOpenModal] = useState(false);
  const [form] = Form.useForm();

  const defaultFileName = `RingBatch_${dayjs().format("YYYYMMDD_HHMM")}`;
  const fileNameRef = useRef("");

  const handleExport = () => {
    const fileName = fileNameRef.current || defaultFileName;
    const csvData = new Blob([exportItemResponse.data], { type: "text/csv" });
    const csvURL = URL.createObjectURL(csvData);
    const link = document.createElement("a");
    link.href = csvURL;
    link.download = `${fileName}.csv`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    clearRequest();
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleFinish = async (values: any) => {
    const { dates, ...data } = values;
    fileNameRef.current = data.name;

    exportItem({
      ...data,
      ...(dates && {
        start: dayjs(dates[0]).startOf("day").toISOString(),
        end: dayjs(dates[1]).endOf("day").toISOString(),
      }),
      limit: 100000,
    });
  };

  useEffect(() => {
    return () => {
      clearRequest();
      form?.resetFields();
      fileNameRef.current = "";
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openModal]);

  useEffect(() => {
    if (exportItemResponse.success) {
      message.success(t("message.exportItemSuccess"));
      form.resetFields();
      handleExport();
      handleCloseModal();
    } else if (exportItemResponse.error) {
      message.error(t(exportItemResponse.error));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exportItemResponse.success, exportItemResponse.error]);

  return (
    <>
      <Button
        type="primary"
        onClick={() => setOpenModal(true)}
        loading={exportItemResponse.loading}
      >
        {t("button.export")}
      </Button>

      <Modal open={openModal} onCancel={handleCloseModal} footer={null}>
        <div>
          <Form
            form={form}
            initialValues={{ name: defaultFileName }}
            layout="vertical"
            onFinish={handleFinish}
          >
            <Form.Item className="flex-1" label="File Name" name="name">
              <Input />
            </Form.Item>
            <Form.Item className="flex-1" label="Date" name="dates">
              <DatePicker.RangePicker className="w-full" />
            </Form.Item>
            <Form.Item className="flex-1" label="Ring Size" name="size">
              <AutoComplete allowClear options={options}>
                <Input />
              </AutoComplete>
            </Form.Item>
            <Form.Item
              className="flex-1"
              label="Manufacturing status"
              name="status"
            >
              <Select>
                <Select.Option value="NOT_DONE">NOT_DONE</Select.Option>
                <Select.Option value="DONE">DONE</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item className="flex justify-end">
              <Button
                onClick={handleCloseModal}
                className="mr-4"
                loading={exportItemResponse.loading}
              >
                Cancel
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                loading={exportItemResponse.loading}
              >
                <ExportOutlined /> Export
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </>
  );
}
