import axios from "axios";

export const baseApi = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  responseType: "json",
  timeout: 15000,
});

export const updateHeaderToken = (token: string) => {
  baseApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
};

export const updateHeaderPermissionKey = (key: string) => {
  baseApi.defaults.headers.common["x-item-uid"] = key;
};
