import { IStatistic } from "@app-interface/statistic";
import { throwErrorMessage } from "@app-utils/errorHandler";

import { baseApi } from "./common";

const getStatistic = async (params?: {
  start: any;
  end: any;
}): Promise<IStatistic> => {
  try {
    const response = await baseApi.get(`admin/statistic`, {
      params,
    });
    return response.data;
  } catch (error: any) {
    throw throwErrorMessage(error);
  }
};

const statisticService = {
  getStatistic,
};

export default statisticService;
