import { throwErrorMessage } from "@app-utils/errorHandler";
import { CreateProductReq, UpdateProductReq } from "../interface/product";
import { baseApi } from "./common";

const getProducts = async (params: any) => {
  try {
    const response = await baseApi.get(`products`, {
      params,
    });
    return response.data;
  } catch (error: any) {
    throw throwErrorMessage(error);
  }
};

const createProduct = async (data: CreateProductReq) => {
  try {
    const response = await baseApi.post(`admin/products`, data);
    return response.data;
  } catch (error: any) {
    throw throwErrorMessage(error);
  }
};

const updateProduct = async (id: number, data: UpdateProductReq) => {
  try {
    const response = await baseApi.patch(`admin/products/${id}`, data);
    return response.data;
  } catch (error: any) {
    throw throwErrorMessage(error);
  }
};

const deleteProduct = async (id: number) => {
  try {
    const response = await baseApi.delete(`admin/products/${id}`);
    return response.data;
  } catch (error: any) {
    throw throwErrorMessage(error);
  }
};

const productServices = {
  getProducts,
  createProduct,
  updateProduct,
  deleteProduct,
};

export default productServices;
