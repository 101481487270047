import { CopyOutlined } from "@ant-design/icons";
import { Button, Card, Checkbox, InputNumber, Modal, message } from "antd";
import { useEffect, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useTranslation } from "react-i18next";

import itemServices from "@app-services/itemServices";
import useService from "@app-services/shared/use_service";

const useActivate = () => useService(itemServices.activate);

export default function ModalActivation({
  uid,
  onCancel,
}: {
  uid: string;
  onCancel: () => void;
}) {
  const { t } = useTranslation();
  const [payload, activate] = useActivate();

  const [isForce, setIsForce] = useState(false);
  const [sectorCount, setSectorCount] = useState(10);

  useEffect(() => {
    if (payload.success) {
      message.success("Generate keys successfully");
    } else if (payload.error) {
      message.error(t(payload.error));
    }
  }, [payload.success, payload.error, t]);

  const handleActivate = () => {
    activate({
      uid,
      force: isForce,
      sectorCount: Number(sectorCount),
    });
  };

  return (
    <Modal title={"Activate Ring"} open onCancel={onCancel} footer={null}>
      {payload.success ? (
        <div className="pt-4">
          <div className="pb-4">
            Partner token: <strong>{payload.data.partnerToken}</strong>
          </div>
          {payload.data?.sectorKeys?.map((i, index) => (
            <Card key={index} className="mb-4" size="small">
              <div>
                <div>
                  <span>Section No.:</span> <strong>{i.sectorNo}</strong>
                </div>
                <div className="flex items-center">
                  <span>Key:</span>{" "}
                  <CopyToClipboard
                    text={i.key}
                    onCopy={() =>
                      message.info("Your key has been copied to clipboard")
                    }
                  >
                    <div className="cursor-pointer">
                      <strong>{i.key}</strong>
                      <CopyOutlined />
                    </div>
                  </CopyToClipboard>
                </div>
              </div>
            </Card>
          ))}
        </div>
      ) : (
        <div>
          <div className="pb-4">
            <div>Sector count:</div>
            <InputNumber
              value={sectorCount}
              onChange={(value) => setSectorCount(Number(value))}
              min={1}
              max={32}
            />
          </div>
          <div className="pb-4">
            <Checkbox
              value={isForce}
              onChange={(e) => setIsForce(e.target.checked)}
            >
              Force activate
            </Checkbox>
          </div>
          <div className="flex justify-end">
            <Button onClick={onCancel} className="mr-4">
              Cancel
            </Button>
            <Button
              type="primary"
              onClick={handleActivate}
              loading={payload.loading}
            >
              Generate key
            </Button>
          </div>
        </div>
      )}
    </Modal>
  );
}
