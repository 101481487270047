import { IAddress } from "./address";
import { IProduct } from "./product";

export interface CreateOrderReq {
  paymentMethod: string;
  addressId: number;
  userId?: number;
  items: CreateOrderItem[];
}

interface CreateOrderItem {
  productId: number;
  quantity: number;
  size: number;
}

interface OrderItem {
  orderId: number;
  productId: number;
  quantity: number;
  size: number;
  createdAt: string;
  updatedAt: string;
  product: IProduct;
}

export interface IOrder {
  id: number;
  userId: number;
  addressId: number;
  paymentMethod: PaymentMethod;
  status: string;
  createdAt: string;
  updatedAt: string;
  address: IAddress;
  orderItems: OrderItem[];
  affiliateId?: number;
  couponId?: number;
}

export interface UpdateOrderReq {
  status: string;
}

export enum OrderStatus {
  PENDING = "PENDING",
  PROCESSING = "PROCESSING",
  COMPLETED = "COMPLETED",
  CANCELED = "CANCELED",
  PAID = "PAID",
  FAILED = "FAILED",
}

export enum PaymentMethod {
  COD = "COD",
  TRANSFER = "TRANSFER",
}
