import { atom, useSetAtom } from "jotai";

import { LocalStorageKeys } from "../../constants/common";
import { INIT_PAYLOAD } from "../constants";
import { getProfileServiceAtom, signinServiceAtom } from "./api_atoms";
import {
  isLoggedAtom,
  permissionKeyAtom,
  signinPayloadAtom,
  tokenAtom,
} from "./atoms";

const clearSigninPayloadAtom = atom(null, (_, set) => {
  set(signinPayloadAtom, INIT_PAYLOAD);
});

const signoutAtom = atom(null, (_, set) => {
  set(isLoggedAtom, false);
  set(tokenAtom, "");
  set(permissionKeyAtom, "");
  window.localStorage.removeItem(LocalStorageKeys.CMS_TOKEN);
  // window.localStorage.removeItem(LocalStorageKeys.PERMISSION_KEY);
  // window.localStorage.removeItem(LocalStorageKeys.PERMISSION_KEY_EXPIRE_AT);
});

const useAuthWriteOnly = () => {
  const onSignin = useSetAtom(signinServiceAtom);
  const onSignout = useSetAtom(signoutAtom);
  const onGetProfile = useSetAtom(getProfileServiceAtom);

  const clearSigninPayload = useSetAtom(clearSigninPayloadAtom);
  return {
    onSignin,
    onSignout,
    clearSigninPayload,
    onGetProfile,
  };
};

export { useAuthWriteOnly };
