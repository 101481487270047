import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Popconfirm, Space, Table, message } from "antd";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { IResponseData } from "../../interface/common";
import { IUser } from "../../interface/user";
import userServices from "../../services/userServices";
import AddOrEditUserModal from "./components/AddOrEditUserModal";

export default function UsersPage() {
  const [messageApi, contextHolder] = message.useMessage();

  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<IResponseData<IUser>>({
    metadata: {
      limit: 20,
      offset: 0,
      count: 0,
    },
  });

  const [userData, setUserData] = useState<IUser | null>(null);

  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    handleGetUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleGetUsers = async (metadata?: any) => {
    setLoading(true);
    try {
      const response = await userServices.getUsers(metadata || data.metadata);
      setData(response);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const handleTableChange = (tableData: any) => {
    const newMetadata = {
      limit: data.metadata?.limit,
      offset: (tableData.current - 1) * +data?.metadata?.limit,
    };
    setData((prev) => ({
      ...prev,
      metadata: {
        ...prev.metadata,
        ...newMetadata,
      },
    }));
    handleGetUsers(newMetadata);
  };

  const handleModalCancel = () => {
    setUserData(null);
    setOpenModal(false);
  };

  const handleSuccess = () => {
    handleGetUsers();
    handleModalCancel();
  };

  const handleDelete = async (id: number) => {
    setLoading(true);
    try {
      const response = await userServices.deleteUser(id);

      if (!!response) {
        messageApi.success("Deleted user success");
        handleGetUsers();
      } else {
        // eslint-disable-next-line no-throw-literal
        throw "Error when delete user";
      }
    } catch (error: any) {
      messageApi.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      {contextHolder}
      <div className="flex justify-end mb-4">
        <Button type="primary" onClick={() => setOpenModal(true)}>
          {t("button.createUser")}
        </Button>
      </div>
      <Table
        rowKey={(row) => row.id}
        loading={loading}
        columns={[
          {
            title: "Name",
            dataIndex: "name",
            key: "name",
          },
          {
            title: "Email",
            dataIndex: "email",
            key: "email",
          },
          {
            title: "Verified At",
            dataIndex: "emailVerifiedAt",
            key: "emailVerifiedAt",
            render: (data) => (
              <div>{data ? format(new Date(data), "PPp") : "--"}</div>
            ),
          },
          {
            title: "Phone",
            dataIndex: "phone",
            key: "phone",
          },
          {
            title: "Role",
            dataIndex: "role",
            key: "role",
          },
          {
            title: "Reference ID",
            dataIndex: "refId",
            key: "refId",
          },
          {
            title: "Created At",
            dataIndex: "createdAt",
            key: "createdAt",
            render: (data) => <div>{format(new Date(data), "PPp")}</div>,
          },
          {
            title: "Updated At",
            dataIndex: "updatedAt",
            key: "updatedAt",
            render: (data) => <div>{format(new Date(data), "PPp")}</div>,
          },
          {
            title: "Action",
            dataIndex: "id",
            key: "action",
            render: (id, record) => (
              <Space>
                <EditOutlined
                  className="cursor-pointer"
                  onClick={() => {
                    setUserData(record as any);
                    setOpenModal(true);
                  }}
                />

                <Popconfirm
                  title="Delete user"
                  description="Are you sure to delete this user?"
                  onConfirm={() => handleDelete(id)}
                  okText="Yes"
                  cancelText="No"
                >
                  <DeleteOutlined className="cursor-pointer ml-4" />
                </Popconfirm>
              </Space>
            ),
          },
        ]}
        dataSource={data?.models as any}
        onChange={handleTableChange}
        scroll={{ x: 800 }}
        pagination={{
          total: +data?.metadata?.count,
          pageSize: +data?.metadata?.limit,
          current: +data?.metadata?.offset / +data?.metadata?.limit + 1,
        }}
      />

      <AddOrEditUserModal
        isOpen={openModal}
        userData={userData}
        onSuccess={handleSuccess}
        onCancel={handleModalCancel}
      />
    </div>
  );
}
