import { Button, Form, Input, Modal, Select, message } from "antd";
import { useEffect, useState } from "react";

import { IUser } from "../../../interface/user";
import userServices from "../../../services/userServices";

export default function AddOrEditUserModal({
  isOpen,
  userData,
  onSuccess,
  onCancel,
}: {
  isOpen?: boolean;
  userData?: IUser | null;
  onSuccess?: () => void;
  onCancel?: () => void;
}) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (userData) {
      // setData(userData);
      form.setFieldsValue(userData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  useEffect(() => {
    return () => {
      form.resetFields();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const handleFinish = async (values: any) => {
    setLoading(true);
    let response = null;
    try {
      if (userData) {
        response = await userServices.updateUser(userData.id, values);
      } else {
        response = await userServices.createUser(values);
      }
      if (!!response) {
        message.success(
          userData ? "Update user success" : "Create user success",
        );
        onSuccess?.();
      }
    } catch (error: any) {
      message.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      title={userData ? "Edit User" : "Create User"}
      open={isOpen}
      onCancel={onCancel}
      footer={null}
    >
      <Form
        layout="vertical"
        initialValues={{ role: "admin" }}
        form={form}
        onFinish={handleFinish}
      >
        <Form.Item label="Name" name="name">
          <Input placeholder="input placeholder" />
        </Form.Item>
        <Form.Item label="Email" name="email">
          <Input placeholder="input placeholder" />
        </Form.Item>
        {/* <Form.Item label="Phone" name="phone">
          <Input placeholder="+84 xxx xxxxxx" />
        </Form.Item> */}
        {!userData && (
          <Form.Item
            label="Password"
            name="password"
            rules={[{ required: true, message: "Please input user password!" }]}
          >
            <Input.Password
              placeholder="User password"
              autoComplete="new-password"
            />
          </Form.Item>
        )}
        <Form.Item
          label="Role"
          name="role"
          rules={[{ required: true, message: "Please input user role!" }]}
        >
          <Select>
            {/* <Select.Option value="user">User</Select.Option> */}
            <Select.Option value="admin">Admin</Select.Option>
            <Select.Option value="finance">Finance</Select.Option>
            <Select.Option value="user">User</Select.Option>
            <Select.Option value="counter">Counter</Select.Option>
          </Select>
        </Form.Item>

        <Form.Item className="flex justify-end">
          <Button onClick={onCancel} className="mr-4">
            Cancel
          </Button>
          <Button type="primary" htmlType="submit" loading={loading}>
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}
