import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Popconfirm, Select, Space, Table, Tag, message } from "antd";
import { format } from "date-fns";
import { useEffect, useState } from "react";

import { IResponseData } from "../../interface/common";
import { IOrder, OrderStatus } from "../../interface/order";
import orderServices from "../../services/orderServices";
import AddOrEditOrderModal from "./components/AddOrEditOrderModal";

const STATUS_COLOR: any = {
  [OrderStatus.CANCELED]: "#f5222d",
  [OrderStatus.COMPLETED]: "#ff7875",
  [OrderStatus.PENDING]: "#b4dd44",
  [OrderStatus.PROCESSING]: "#1677ff",
};
const OPTIONS_DATA = Object.values(OrderStatus).map((i) => ({
  label: i,
  value: i,
}));

export default function OrdersPage() {
  const [messageApi, contextHolder] = message.useMessage();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<IResponseData<IOrder>>({
    metadata: {
      limit: 20,
      offset: 0,
      count: 0,
      status: [OrderStatus.PAID],
    },
  });

  const [orderData, setOrderData] = useState<IOrder | null>(null);

  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    handleGetOrders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleGetOrders = async (metadata?: any) => {
    setLoading(true);
    try {
      const params = metadata || data.metadata;
      const status = Array.isArray(params.status)
        ? params.status.join(",")
        : undefined;
      const response = await orderServices.getOrders({ ...params, status });
      setData({ ...response, metadata: { ...data.metadata, ...metadata } });
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const handleTableChange = (tableData: any) => {
    const newMetadata = {
      limit: data.metadata?.limit,
      offset: (tableData.current - 1) * +data?.metadata?.limit,
    };
    setData((prev) => ({
      ...prev,
      metadata: {
        ...prev.metadata,
        ...newMetadata,
      },
    }));
    handleGetOrders(newMetadata);
  };

  const handleModalCancel = () => {
    setOrderData(null);
    setOpenModal(false);
  };

  const handleSuccess = () => {
    handleGetOrders();
    handleModalCancel();
  };

  const handleDelete = async (id: number) => {
    setLoading(true);
    try {
      const response = await orderServices.deleteOrder(id);

      if (!!response) {
        messageApi.success("Deleted order success");
        handleGetOrders();
      } else {
        // eslint-disable-next-line no-throw-literal
        throw "Error when delete order";
      }
    } catch (error: any) {
      messageApi.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleFilterChange = (value: string[]) => {
    const newMetadata = {
      ...data.metadata,
      status: value,
    };
    setData((prev) => ({
      ...prev,
      metadata: {
        ...prev.metadata,
        ...newMetadata,
      },
    }));
    handleGetOrders(newMetadata);
  };

  return (
    <div>
      {contextHolder}
      {/* <div className="flex justify-end mb-4">
        <Button type="primary" onClick={() => setOpenModal(true)}>
          {t("button.createOrder")}
        </Button>
      </div> */}
      <div className="text-right pb-4">
        <Select
          mode="multiple"
          allowClear
          style={{ minWidth: 300, textAlign: "left" }}
          placeholder="Please filter by status"
          value={data.metadata?.status}
          onChange={handleFilterChange}
          options={OPTIONS_DATA}
        />
      </div>

      <Table
        rowKey={(row) => row.id}
        loading={loading}
        columns={[
          // {
          //   title: "User",
          //   dataIndex: "userId",
          //   key: "userId",
          //   render: (userId) => (
          //     <div className="cursor-pointer underline text-blue-500">
          //       {userId}
          //     </div>
          //   ),
          // },
          {
            title: "Status",
            dataIndex: "status",
            key: "status",
            render: (status) => (
              <Tag color={STATUS_COLOR[status]} className="text-sm">
                {status}
              </Tag>
            ),
          },
          {
            title: "Payment Method",
            dataIndex: "paymentMethod",
            key: "paymentMethod",
          },
          {
            title: "Phone",
            dataIndex: "id",
            key: "phone",
            render: (_, record) => (
              <div>{record?.phone || record?.user?.phone}</div>
            ),
          },
          {
            title: "Address",
            dataIndex: ["address", "value"],
            key: "address",
          },
          {
            title: "Email",
            dataIndex: "email",
            key: "email",
          },
          {
            title: "Amount",
            dataIndex: "amount",
            key: "amount",
            render: (data) =>
              new Intl.NumberFormat("en-US").format(data) + " đ",
          },
          {
            title: "Affiliate ID",
            dataIndex: "affiliateId",
            key: "affiliateId",
          },
          {
            title: "Coupon ID",
            dataIndex: "couponId",
            key: "couponId",
          },
          {
            title: "Created At",
            dataIndex: "createdAt",
            key: "createdAt",
            render: (data) => <div>{format(new Date(data), "PPp")}</div>,
          },
          {
            title: "Updated At",
            dataIndex: "updatedAt",
            key: "updatedAt",
            render: (data) => <div>{format(new Date(data), "PPp")}</div>,
          },
          {
            title: "Action",
            dataIndex: "id",
            key: "action",
            render: (id, record) => (
              <Space>
                <EditOutlined
                  className="cursor-pointer"
                  onClick={() => {
                    setOrderData(record as any);
                    setOpenModal(true);
                  }}
                />

                <Popconfirm
                  title="Delete order"
                  description="Are you sure to delete this order?"
                  onConfirm={() => handleDelete(id)}
                  okText="Yes"
                  cancelText="No"
                >
                  <DeleteOutlined className="cursor-pointer ml-4" />
                </Popconfirm>
              </Space>
            ),
          },
        ]}
        dataSource={data?.models as any}
        onChange={handleTableChange}
        scroll={{ x: 800 }}
        pagination={{
          total: data?.metadata?.count,
          pageSize: data?.metadata?.limit,
          current: data?.metadata?.offset / data?.metadata?.limit + 1,
        }}
      />

      <AddOrEditOrderModal
        isOpen={openModal}
        orderData={orderData}
        onSuccess={handleSuccess}
        onCancel={handleModalCancel}
      />
    </div>
  );
}
