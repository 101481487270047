import { throwErrorMessage } from "@app-utils/errorHandler";
import { CreateOrderReq, UpdateOrderReq } from "../interface/order";
import { baseApi } from "./common";

const getOrders = async (params: any) => {
  try {
    const response = await baseApi.get(`admin/orders`, {
      params,
    });
    return response.data;
  } catch (error: any) {
    throw throwErrorMessage(error);
  }
};

const createOrder = async (data: CreateOrderReq) => {
  try {
    const response = await baseApi.post(`admin/orders`, data);
    return response.data;
  } catch (error: any) {
    throw throwErrorMessage(error);
  }
};

const updateOrder = async (id: number, data: UpdateOrderReq) => {
  try {
    const response = await baseApi.patch(`admin/orders/${id}`, data);
    return response.data;
  } catch (error: any) {
    throw throwErrorMessage(error);
  }
};

const deleteOrder = async (id: number) => {
  try {
    const response = await baseApi.delete(`admin/orders/${id}`);
    return response.data;
  } catch (error: any) {
    throw throwErrorMessage(error);
  }
};

const userServices = {
  getOrders,
  createOrder,
  updateOrder,
  deleteOrder,
};

export default userServices;
