import { Alert, Button, Form, Input } from "antd";
import { useAtomValue } from "jotai";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import validator from "validator";

import MTLogoSvg from "../../components/svg/MTLogoSvg";
import {
  isLoggedAtom,
  signinPayloadAtom,
  useAuthWriteOnly,
} from "../../jotai/auth";

export default function LoginPage() {
  const { t } = useTranslation();
  const { onSignin, onGetProfile } = useAuthWriteOnly();
  const navigate = useNavigate();
  const signinPayload = useAtomValue(signinPayloadAtom);
  const isLogged = useAtomValue(isLoggedAtom);

  const onFinish = ({ username, password }: any) => {
    validator.isEmail(username);
    onSignin({ username, password });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  useEffect(() => {
    if (isLogged) {
      onGetProfile();
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLogged]);

  return (
    <div className="min-h-screen flex items-center justify-center">
      <div className="w-[360px]">
        <div className="[&>svg>path]:fill-black flex justify-center py-5">
          <MTLogoSvg />
        </div>
        <Form
          layout="vertical"
          style={{ maxWidth: 600 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label={t("form.email.label")}
            name="username"
            rules={[{ required: true, message: t("form.email.requireRule") }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label={t("form.password.label")}
            name="password"
            rules={[
              { required: true, message: t("form.password.requireRule") },
            ]}
          >
            <Input.Password />
          </Form.Item>

          {/* <Form.Item
            name="remember"
            valuePropName="checked"
          >
            <Checkbox>Remember me</Checkbox>
          </Form.Item> */}
          {signinPayload.error && (
            <div className="pb-6 text-left">
              <Alert message={t(signinPayload.error)} type="error" showIcon />
            </div>
          )}

          <Form.Item className="text-center">
            <Button
              type="primary"
              htmlType="submit"
              loading={signinPayload.loading}
            >
              {t("button.signin")}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}
