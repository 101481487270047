import { Button, Image, Input, Modal, Spin } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { EKYC_STATUSES } from "@app-constants/eKYC";
import eKYCServices from "@app-services/eKYCServices";
import useService from "@app-services/shared/use_service";

const useGetDetail = () => useService(eKYCServices.getDetail);
const useUpdateStatus = () => useService(eKYCServices.updateStatus);

const IMAGE_KEYS = [
  "id_front",
  "id_back",
  "face_front",
  "face_left",
  "face_right",
];

export default function EKYCDetailModal({
  id,
  onCancel,
  onUpdateStatus,
}: {
  id: number;
  onCancel: () => void;
  onUpdateStatus: () => void;
}) {
  const { t } = useTranslation();
  const [payload, getDetail] = useGetDetail();

  const [statusPayload, updateStatus] = useUpdateStatus();

  const [note, setNote] = useState("");


  useEffect(() => {
    handleGetDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (statusPayload.success) {
      onUpdateStatus?.();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusPayload.success]);

  const handleGetDetail = () => {
    getDetail(id);
  };

  const handleUpdate = (status: string) => {
    updateStatus(id, { status, note });
  };

  const renderContent = () => {
    if (payload.loading) {
      return (
        <div className="flex items-center justify-center py-10">
          <Spin />
        </div>
      );
    } else if (payload.error) {
      return <div>{payload.error}</div>;
    }

    return (
      <div>
        <Image.PreviewGroup
          preview={{
            countRender: (current: number, total: number) => (
              <div>
                <div>{IMAGE_KEYS[current - 1]}</div>
                <div className="text-center">
                  {current} / {total}
                </div>
              </div>
            ),
          }}
        >
          <div className="flex flex-wrap gap-[20px]">
            {payload?.data?.uploadedUrls?.map((data) => (
              <div key={data.name}>
                <div>{data.name}</div>
                <div className="w-[100px] h-[100px]">
                  <Image
                    alt={data.name}
                    src={data.url}
                    style={{ objectFit: "contain", width: 100, height: 100 }}
                  />
                </div>
              </div>
            ))}
          </div>
        </Image.PreviewGroup>
        <div>
          <Input.TextArea
            rows={4}
            placeholder="Note"
            onChange={(e) => setNote(e.target.value)}
          />
        </div>
        <div className="flex justify-end pt-3">
          <Button
            type="primary"
            danger
            onClick={() => handleUpdate(EKYC_STATUSES.FAILED)}
          >
            {t("button.reject")}
          </Button>
          <Button
            type="primary"
            className="ml-4"
            onClick={() => handleUpdate(EKYC_STATUSES.COMPLETED)}
          >
            {t("button.approve")}
          </Button>
        </div>
      </div>
    );
  };

  return (
    <Modal title={"EKYC Preview"} open onCancel={onCancel} footer={null}>
      <div className="relative">
        <div>{renderContent()}</div>
        {statusPayload?.loading && (
          <div className="absolute w-full h-full flex items-center justify-center left-0 top-0 z-10">
            <Spin />
          </div>
        )}
      </div>
    </Modal>
  );
}
