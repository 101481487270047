import { IResponseData } from "@app-interface/common";
import { IEKYC, UpdateStatusReq } from "@app-interface/eKYC";
import { throwErrorMessage } from "@app-utils/errorHandler";

import { baseApi } from "./common";

const getList = async (params: any): Promise<IResponseData<IEKYC>> => {
  const response = await baseApi.get(`admin/ekyc`, {
    params,
  });
  try {
    return response.data;
  } catch (error: any) {
    throw throwErrorMessage(error);
  }
};

const getDetail = async (id: number): Promise<IEKYC> => {
  const response = await baseApi.get(`admin/ekyc/${id}`);
  try {
    return response.data;
  } catch (error: any) {
    throw throwErrorMessage(error);
  }
};

const updateStatus = async (
  id: number,
  data: UpdateStatusReq,
): Promise<IEKYC> => {
  const response = await baseApi.patch(`admin/ekyc/${id}`, data);

  try {
    return response.data;
  } catch (error: any) {
    throw throwErrorMessage(error);
  }
};

const eKYCServices = {
  getList,
  getDetail,
  updateStatus,
};

export default eKYCServices;
