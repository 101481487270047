import { Select, Table } from "antd";
import { useEffect, useState } from "react";

import { EKYC_STATUSES } from "@app-constants/eKYC";
import { IEKYC } from "@app-interface/eKYC";
import eKYCServices from "@app-services/eKYCServices";
import useService from "@app-services/shared/use_service";

import EKYCDetailModal from "./components/EKYCDetailModal";

const OPTIONS_DATA = [
  EKYC_STATUSES.SUBMITTED,
  EKYC_STATUSES.FAILED,
  EKYC_STATUSES.COMPLETED,
].map((i) => ({ label: i, value: i }));

const useGetList = () => useService(eKYCServices.getList);
export default function EKYCPage() {
  const [payload, getList] = useGetList();

  const [selectedId, setSelectedId] = useState(0);

  const [params, setParams] = useState({
    limit: 10,
    offset: 0,
    status: [EKYC_STATUSES.SUBMITTED],
  });

  useEffect(() => {
    handleGetList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  const handleUpdateStatus = () => {
    setSelectedId(0);
    handleGetList();
  };

  const handleChange = (value: string[]) => {
    setParams((prev) => ({
      ...prev,
      limit: 10,
      offset: 0,
      status: value,
    }));
  };

  const handleTableChange = (tableData: any) => {
    const newMetadata = {
      limit: payload.data.metadata?.limit,
      offset: (tableData.current - 1) * +payload?.data.metadata?.limit,
    };
    setParams((prev) => ({
      ...prev,
      ...newMetadata,
    }));
  };

  const handleGetList = () => {
    getList({ ...params, status: params.status?.join(",") });
  };

  return (
    <div>
      <div className="text-right pb-4">
        <Select
          mode="multiple"
          allowClear
          style={{ minWidth: 300, textAlign: "left" }}
          placeholder="Please select"
          value={params.status}
          onChange={handleChange}
          options={OPTIONS_DATA}
        />
      </div>

      <Table<IEKYC>
        rowKey={(row) => row.id}
        loading={payload.loading}
        columns={[
          {
            title: "id",
            dataIndex: "id",
            key: "id",
            render: (id: number, record) =>
              record.status === EKYC_STATUSES.SUBMITTED ? (
                <div
                  className="underline cursor-pointer text-blue-700"
                  onClick={() => setSelectedId(id)}
                >
                  {id}
                </div>
              ) : (
                <div>{id}</div>
              ),
          },
          {
            title: "status",
            dataIndex: "status",
            key: "status",
          },
          {
            title: "note",
            dataIndex: "note",
            key: "note",
          },
        ]}
        dataSource={payload?.data?.models}
        onChange={handleTableChange}
        scroll={{ x: 800 }}
        pagination={{
          total: payload?.data?.metadata?.count || 0,
          pageSize: payload?.data?.metadata?.limit || 0,
          current:
            (payload?.data?.metadata?.offset || 0) /
              (payload?.data?.metadata?.limit || 0) +
            1,
        }}
      />

      {!!selectedId && (
        <EKYCDetailModal
          id={selectedId}
          onCancel={() => setSelectedId(0)}
          onUpdateStatus={handleUpdateStatus}
        />
      )}
    </div>
  );
}
